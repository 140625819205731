import {Injectable} from '@angular/core';
import {collection, doc,} from "@angular/fire/firestore";
import {Repository} from "../../persistence/repository";
import {PricedItem, PriceList} from "./price-lists/price-lists.component";
import {forkJoin, map, Observable, take} from "rxjs";
import {FirestoreWrapper} from "../../persistence/firestoreWrapper";
import {ConverterRegistry} from "../../persistence/converter-registry.service";

@Injectable({
    providedIn: 'root'
})
export class PriceListRepository extends Repository<PriceList> {


    constructor(firestore: FirestoreWrapper) {
        super(firestore, ConverterRegistry.find('PriceList'))
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection(`priceLists`).withConverter(this.converter);
    }


    override findById(id: string): Observable<PriceList | undefined> {
        let collectionReference = collection(doc(this.getCollectionReference(), id).withConverter(this.converter), 'items').withConverter(ConverterRegistry.find('PricedItem'));
        return forkJoin([
                super.findById(id).pipe(take(1)),
                this.firestoreWrapper.getAllFromCollection(collectionReference).pipe(take(1))
            ]
        ).pipe(
            map(([list, items]) => {
                let priceList = list as PriceList;
                priceList.items = items as PricedItem[]
                return priceList
            }))

    }


    override save(entity: PriceList, noNotification: boolean = false): Promise<PriceList> {
        const batch = this.firestoreWrapper.writeBatch();
        if (!entity.id) entity.id = this.getId()

        let priceListRef = doc(this.getCollectionReference(), entity.id);
        batch.set(priceListRef, entity)

        let pricedItemConverter = ConverterRegistry.find('PricedItem')
        entity.items.forEach(value => {
            value.id = value.code
            batch.set(doc(priceListRef, 'items', value.code).withConverter(pricedItemConverter), value);
        })

        return batch.commit().then(value => entity)
    }
}
