import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {PricedItem, PriceList} from "../price-lists/price-lists.component";
import {MatTable} from "@angular/material/table";
import {DataSource, SelectionModel} from "@angular/cdk/collections";
import {MatPaginator} from "@angular/material/paginator";
import {PriceListService} from "../price-list.service";
import {PricedItemRepository} from "../pricedItem.repository";
import {endBefore, limit, limitToLast, startAfter} from "@angular/fire/firestore";
import algoliasearch, {SearchClient} from 'algoliasearch';
import {environment} from "../../../../environments/environment";
import {SearchOptions} from "@algolia/client-search";
import {animate, state, style, transition, trigger} from "@angular/animations";


@Component({
    selector: 'priced-item-selector',
    templateUrl: './priced-item-selector.component.html',
    styleUrls: ['./priced-item-selector.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0', 'padding-top': '0', 'padding-bottom': '0'})),
            state('expanded', style({height: '*', 'padding-top': '*', 'padding-bottom': '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class PricedItemSelectorComponent implements OnInit {

    lists: Observable<PriceList[]>;
    selectedList: PriceList[];
    displayedColumns: string[] = ['select', 'code', 'description', 'unitOfMeasure', 'cost', 'actions'];
    @ViewChild(MatTable) tableQuery: MatTable<PricedItem>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    searchClient: SearchClient = algoliasearch(environment.algolia.appId, environment.algolia.apiToken);

    dataSource: MatTableAlgoliaDataSource<PricedItem>
    selection = new SelectionModel<PricedItem>(true, []);

    constructor(private priceListService: PriceListService) {}

    ngOnInit(): void {
        this.lists = this.priceListService.getAllLists()
        this.paginator?.firstPage()
    }

    onPriceListSelected() {
        //let pricedItemRepository = new PricedItemRepository(this.priceListService.priceListRepository.firestore, this.selectedList[0]);

        this.dataSource = new MatTableAlgoliaDataSource<PricedItem>(this.searchClient, this.paginator, this.selectedList[0], this.selectedList[0].index || environment.algolia.priceListIndex);
        this.dataSource.updated.subscribe(() => this.tableQuery.renderRows())
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    filter(event: string) {
        this.dataSource.filter(event)
    }

    onExpandLine(element, event) {
        event.preventDefault();
        event.stopPropagation();

        element.expanded = !element.expanded;
    }

    sort(parents: any[]) {
        if(!parents) return []

        return parents.sort((a, b) => a.code.length < b.code.length ? -1 : 1);
    }
}

export class MatTableFirebaseDataSource<T> extends DataSource<T> {
    public updated: EventEmitter<void> = new EventEmitter<void>()
    subject: BehaviorSubject<T[]>

    constructor(private repository: PricedItemRepository, private paginator: MatPaginator) {
        super();

        paginator.length = repository.count()
        paginator.page.subscribe(pageEvent => {
            console.log("page event", pageEvent)
            let itemCode: string | null
            let startingValueConstraint
            let limitConstraint

            if (pageEvent.previousPageIndex !== undefined && pageEvent.pageIndex > pageEvent.previousPageIndex) {
                // @ts-ignore
                itemCode = this.subject.value ? this.subject.value[this.subject.value.length - 1].code : null
                startingValueConstraint = startAfter(itemCode);
                limitConstraint = limit(pageEvent.pageSize)
            } else if (pageEvent.previousPageIndex !== undefined && pageEvent.pageIndex < pageEvent.previousPageIndex) {
                // @ts-ignore
                itemCode = this.subject.value ? this.subject.value[0].code : null
                startingValueConstraint = endBefore(itemCode);
                limitConstraint = limitToLast(pageEvent.pageSize)

            }

            // @ts-ignore
            this.repository.page(paginator.pageSize, startingValueConstraint, limitConstraint).subscribe(this.onNewPage)
        })

    }

    onNewPage = value => {
        // @ts-ignore
        this.subject.next(value)
        this.updated.next()
    };

    get size() {
        return this.subject.value.length
    }

    get data() {
        return this.subject.value
    }

    override connect(): BehaviorSubject<T[]> {
        this.subject = new BehaviorSubject<T[]>([]);
        this.repository.page(this.paginator.pageSize, limit(this.paginator.pageSize)).subscribe(this.onNewPage)
        return this.subject;
    }

    override disconnect() {
        this.subject.complete()
    }

    moveToFirstPage() {
        this.paginator.firstPage();
    }

    filter(toSearch: string) {

        //this.repository.page(this.paginator.pageSize, where('description', ))

        //.pipe(tap(value => {
        //        this.paginator.length = value.length
        //        this.paginator?.firstPage()
        //    }));
    }
}

export class MatTableAlgoliaDataSource<T> extends DataSource<T> {
    public updated: EventEmitter<void> = new EventEmitter<void>()
    subject: BehaviorSubject<T[]>

    constructor(private repository: SearchClient, private paginator: MatPaginator, private priceList: PriceList, private indexName: string) {
        super();

        paginator.page.subscribe(pageEvent => this.query(pageEvent.pageIndex))

    }

    onNewPage = value => {
        // @ts-ignore
        this.subject.next(value)
        this.updated.next()
    };

    get size() {
        return this.subject.value.length
    }

    get data() {
        return this.subject.value
    }

    override connect(): BehaviorSubject<T[]> {
        this.subject = new BehaviorSubject<T[]>([]);
        this.query(0)
        return this.subject;
    }

    override disconnect() {
        this.subject.complete()
    }

    moveToFirstPage() {
        this.paginator.firstPage();
    }

    query(page: number, toSearch: string = '') {
        let params = {
            hitsPerPage: this.paginator.pageSize,
            page: page
        } as SearchOptions;

        let search = this.repository.search([{
            indexName: this.indexName,
            params: params,
            query: toSearch.trim()
        }]);

        search.then((value: any) => {
            console.log("algolia response", value)
            let result = value.results[0];
            this.subject.next(result.hits)
            this.paginator.length = result.nbHits
        })
    }

    filter(toSearch: string) {
        this.query(0, toSearch)
    }
}
